<template>
  <v-footer
    color="#0D3282"
    padless
  >
    <div class="mt-5 mb-5 ml-5">
        <img width="80%" src="@/assets/logo.png" alt="HC Serafim">
    </div>
    <v-row
      justify="center"
      no-gutters
    >
        <v-btn
        v-for="(link,i) in links"
        :key="i"
        color="white"
        text
        rounded
        class="my-2"
        :to="link.src"
        >
        {{ link.nome }}
        </v-btn>
        <v-col
        class="#0D3282 py-4 text-center white--text"
        cols="12"
        >
        ©{{ new Date().getFullYear() }}
        — <strong>HC Serafim</strong> — producced by <i>W.P.I</i> 
        </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
    name: 'foot',
    data: () => ({
      links: [
        {nome:'Home',src:'/'},
        {nome:'Empresa',src:'Empresas'},
        {nome:'Serviços',src:'Servicos'},
        {nome:'Fale Conosco',src:'Fale'}
      ],
    }),
}
</script>