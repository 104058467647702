<template>
    <v-container class="corpo">
        <v-container class="text-center">
            <v-row 
                class="d-flex"
                justify="center"
                no-gutters>
                <img width="20%" src="@/assets/hc.png" alt="">
            </v-row>  
            <v-row class=" container texto d-flex">
                Empresa de terraplenagem e locação de equipamentos para terraplenagem. <br>
                Atuando no segmento de terraplenagem desde 2000, a HC Serafim é uma empresa de terraplenagem, localizada em São Paulo, especializada na prestação de serviços de terraplenagem e de locação de equipamentos, máquinas e caminhões para o setor público e privado.
                <br><br>
                A HC Serafim possui uma frota moderna que constantemente é submetida à manutenção preventiva para assim, oferecer serviços de terraplenagem com qualidade, eficiência, rapidez e segurança.<br> Além disso, a HC Serafim também se preocupa com a capacitação de seus funcionários que periodicamente participam de treinamentos sobre NR-06 (Equipamentos de Proteção Individual - EPI),<br> NR-11 (Transporte, Movimentação, Armazenagem e Manuseio de Materiais), NR-18 (Condições e Meio Ambiente de Trabalho na Indústria da Construção), informações sobre os Equipamentos de Proteção Coletiva (EPC),<br> organização e limpeza, riscos elétricos etc.
                <br><br>
                Assim, a HC Serafim garante que seus serviços de terraplenagem são executados por profissionais qualificados, <br> experientes e que utilizarão equipamentos seguros e de alta produtividade.
            </v-row>
            <v-row class="d-flex">
                <v-col>
                    <v-card
                        height="400"
                        elevation="0"
                    >
                        <v-icon style="color:#0D3282;font-size:7.5em">mdi-bullseye-arrow</v-icon>
                        <v-card-title style="margin-left:35%!important">
                        MISSÃO 
                        </v-card-title>
                        <v-card-text>
                            Oferecer serviços de terraplenagem e locação de equipamentos para terraplenagem com qualidade, eficiência, rapidez e segurança, dentro dos prazos estipulados utilizando equipamentos apropriados e profissionais capacitados.
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card
                        height="400"
                        elevation="0"
                    >
                        <v-icon style="color:#0D3282;font-size:7.5em">mdi-check-underline</v-icon>
                        <v-card-title style="margin-left:35%!important">
                            OBJETIVO
                        </v-card-title>
                        <v-card-text>
                            Conseguir a satisfação de nossos clientes oferecendo serviços de terraplenagem e locação de equipamentos 
                            <br> com qualidade e a baixos custos. 
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card
                        height="400"
                        elevation="0"
                    >
                        <v-icon style="color:#0D3282;font-size:7.5em">mdi-target-account</v-icon>
                        <v-card-title style="margin-left:35%!important">
                            VALORES
                        </v-card-title>
                        <v-card-text>
                            - Capacitação
                            <br> - Comprometimento
                            <br> - Integridade
                            <br> - Melhoria contínua
                            <br> - Pontualidade
                            <br> - Segurança
                            <br> - Superação dos resultados
                            <br> - Valorização humana
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>  
        </v-container>
    </v-container>
</template>

<script>
export default {
    name:'Empresa'
}
</script>

<style scoped>
 .texto{
     padding: 3em;
     justify-content: flex-start!important;
     text-align: left;
     font-weight: 300;
 }
 @media only screen and (max-width: 600px) {
  .corpo{
      margin-bottom: 12em;
  }
}
</style>