<template>
  <div>
    <carrosel-home/>
    <empresa class="empresa"/>
    <servicos/>
    <mapa/>
  </div>
</template>

<script>
import CarroselHome from '../components/CarroselHome.vue'
import Empresa from '../components/Empresa.vue'
import Mapa from '../components/Mapa.vue'
import Servicos from '../components/Servicos.vue'

  export default {
    name: 'Home',

    components: {
      CarroselHome,
      Empresa,
      Servicos,
        Mapa,
    },
  }

</script>


<style scoped>
.empresa{
  display: initial;
}
@media only screen and (max-width: 600px) {
  .empresa{display: none;}
}
</style>