<template>
    <v-app-bar
      app
      color="#0D3282"
      dark
      class="corpo"
    >
        <div class="d-flex align-center">
                <img
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                width="120"
                />
        </div>      
        <v-container class="ml-5 d-flex">
            <v-btn
            to="/"
            text
            small
            >
            <span class="mr-2">Home</span>
            </v-btn>
            <v-btn
            to="Empresas"
            text
            small
            >
            <span class="mr-2">Empresa</span>
            </v-btn>
            <v-btn
            to="Servicos"
            text
            small
            >
            <span class="mr-2">Serviços</span>
            </v-btn>
            <v-btn
            to="Fale"
            text
            small
            >
            <span class="mr-2">Fale Conosco</span>
            </v-btn>            
        </v-container>
        <v-spacer></v-spacer>
        <v-btn 
            href="https://api.whatsapp.com/send?phone=5511992274765"
            class="mr-5" 
            target="_blank"
            fab 
            icon>
            <v-icon large>mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn 
            href="https://www.facebook.com/HC-Serafim-Terraplenagem-e-Loca%C3%A7%C3%B5es-ME-291317114240915/" 
            class="mr-5" 
            target="_blank"
            fab 
            icon>
            <v-icon large>mdi-facebook</v-icon>
        </v-btn>
        <v-btn 
            href="https://www.instagram.com/hcserafim/" 
            class="mr-5" 
            target="_blank"
            fab 
            icon>
            <v-icon large>mdi-instagram</v-icon>
        </v-btn>
    </v-app-bar>
</template>


<script>
export default {
    name:'NavBar',
    data: () => ({
    }),
    
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
}
</style>