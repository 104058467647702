<template>
    <v-container class="text-center corpo" fluid>
        <h2 style="color:#0D3282;font-size:4em;font-weight:bolder">NOSSOS CONTATOS</h2>
        <br><br>
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.7158778499356!2d-46.588408884327826!3d-23.506741165464213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5f4edf2db931%3A0xf80baf2c6f9b49fb!2sR.%20Sobral%20J%C3%BAnior%2C%20167%20-%20Vila%20Maria%20Alta%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2002130-020!5e0!3m2!1ses!2sbr!4v1623711141122!5m2!1ses!2sbr" 
            width="100%" 
            height="450" 
            style="border:0;" 
            allowfullscreen="" 
            loading="lazy">
        </iframe>
        <v-row class="d-flex align-center mt-5 mb-5">
            <v-col class="contatos">
                <h1>Endereço:</h1> <br>
                R: Sobral Júnior, nº: 167 <br>
                Vila Maria Alta - São Paulo/SP <br>
                CEP: 02130-020
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="contatos">
                <h1>Contatos:</h1> <br> 
                <v-icon style="color:#0D3282">mdi-email-check-outline</v-icon> contato@hcserafim.com.br <br> <br>
                <a class="link" href="https://api.whatsapp.com/send?phone=5511992274765" target="_blank" rel="noopener noreferrer"> 
                    <v-icon style="color:#0D3282">mdi-whatsapp</v-icon> : +55 (11) 99227-4765
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name:'Mapa'
}
</script>


<style scoped>
    .link{
        text-decoration: none!important;
        color: #0D3282;
    }
    .contatos{
        color: #0D3282;
    }


</style>