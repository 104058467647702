<template>
  <v-app class="app">    
    <nav-bar/>
    <v-main>
      <router-view/>
    </v-main>
    <foot/>
  </v-app>
</template>

<script>
import Foot from './components/Foot.vue';
import NavBar from './components/NavBar.vue'
export default {
  name: 'App',
  components:{
    NavBar,
    Foot,
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .app{
    width: 100vh!important;
  }
  
}
</style>
