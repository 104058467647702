import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Empresas',
    name: 'Empresas',
    component: () => import('../views/Empresas.vue')
  },
  {
    path: '/Servicos',
    name: 'Servicos',
    component: () => import('../views/Servicos.vue')
  }
  ,
  {
    path: '/Fale',
    name: 'Fale',
    component: () => import('../views/Fale.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
