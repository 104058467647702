<template>
    <v-container class="text-center">
        <v-row class="d-flex align-center mt-5" >
            <v-col class="textos" style="color:#0D3282;font-size:4em;font-weight:bolder" cols="4">
                LOCAÇÃO DE EQUIPAMENTOS
                <v-btn
                    class="botoes"
                    elevation="8" 
                    small 
                    rounded 
                    color="#0D3282"
                    to="Servicos">
                    <div style="color:white">
                        <v-icon>mdi-plus</v-icon> Saiba Mais
                    </div>
                </v-btn>
            </v-col>
            <v-col class="imagens" cols="8">
                <img width="70%" src="@/assets/Equip/loc.jpg" alt="Locação de Equipamento">
            </v-col>
        </v-row>
        <br><br>
        <v-row class="d-flex align-center mt-5" >
            <v-col class="imagens" cols="8">
                <img width="70%" src="@/assets/Terra/terra.jpg" alt="Locação de Equipamento">
            </v-col>
            <v-col class="textos" style="color:#0D3282;font-size:4em;font-weight:bolder" cols="4" >
                TERRAPLANAGEM
                <v-btn
                    class="botoes"
                    elevation="8" 
                    small 
                    rounded 
                    color="#0D3282"
                    to="Servicos">
                    <div style="color:white">
                        <v-icon>mdi-plus</v-icon> Saiba Mais
                    </div>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Servicos'
}
</script>

<style scoped>
        .imagens{
            display: initial;
        }
        .textos{
            width: 0;
        }
        .botoes{
            width: 100%;
        }

@media only screen and (max-width: 600px) {
        .imagens{
            display: none;
        }
        .textos{
            width: 100vh;
            margin-left: 1em!important;
            margin-bottom: 2em!important;
        }
        .botoes{
            width: 140%;
        }
    }
</style>