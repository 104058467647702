<template>
  <v-carousel
    cycle
    height="950"
    hide-delimiter-background
    show-arrows-on-hover
    transition="fade-transition"
    class="slider"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
      :src="require('@/assets/Slide/'+ slide.img)"      
    >
        <v-sheet
            color="transparent"
            height="80%"
            class="sheeters"
        >
            <v-row
            class=" corpoTexto mr-4"            
            >
            <div v-html="slide.desc" class=" texto text-h3">
            </div>
            </v-row>
        </v-sheet>      
    </v-carousel-item>    
  </v-carousel>
</template>

<script>
export default {
    name:'CarroselHome',
    data: () => ({
        slides:[
            {img:'equip.jpg',desc:'SERVIÇOS COMPLETOS <br> EM TERRAPLANAGEM'},
            {img:'locacao.jpg',desc:'LOCAÇÃO DE EQUIPAMENTOS <br> DE TERRAPLANAGEM  <br> COM QUALIDADE <br> E CUSTO BENEFÍCIO'},
            {img:'servico.jpg',desc:'LIMPEZA DE RIOS <br> DRENAGEM <br> EQUIPAMENTOS ROBUSTOS E EFICIENTES'},
        ]
    }),
}
</script>


<style scoped>
    .texto{
        font-size: 3em!important;
        font-weight: 300!important;
        color: white!important;
    }
    .corpoTexto{
        align-items: flex-end!important;
        justify-content: flex-end!important;
    }
    .sheeters{
        width: 100%;
        margin-top: 5em;
    }
    .slider{
        display: block;
    }
    @media only screen and (max-width: 600px) {
        .slider{
            display: block;
        }
        .sheeters{
            width: 95%;
            margin-left: 1em;
        }
    }
</style> 